import React,{useContext,useEffect, useState,useRef } from 'react';
import { getRequest, postRequest,deleteRequest } from '../../../api/ApiController';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../../assets/css/style.css';
import '../../../assets/css/iconsmenu.css'
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../../components/UtilContext";
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import { DateTime } from "luxon";
import { Avatar } from 'primereact/avatar';


export default function TrainingInvitationList({preview=null}){

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [printVisible,setPrintVisible] = useState(false);
    const [printValue,setPrintValue] = useState("Zoznam");

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({name:true,invitation_date:true,invitation_time:true,
      'training.name':true,customs_state:true,'training_company.name':true,'contact.name':true,custom_employees:true
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [editFormGauge, setEditFormGauge] = useState([]);

    const ref = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

      function matchAny(data, filterParams) {
        //data - the data for the row being filtered
        //filterParams - params object passed to the filter
        //RegExp - modifier "i" - case insensitve
    
        var match = false;
        const regex = RegExp(filterParams.value, 'i');
    
        for (var key in data) {
            if (regex.test(data[key]) === true) {
                match = true;
            }
        }
        return match;
      }

      function searchTable(){
        ref.current.setFilter(matchAny, { value: searchValue});
        setSearchIconColor("active");
        if (searchValue === " ") {
          ref.current.clearFilter()
        }
        setVisibleSearch(false);
      }
    
        const accept = () => {
            let rows_to_delete = [];
            rows_to_delete = ref.current.getSelectedData();
            rows_to_delete.forEach(element => {
              console.log(element);
              deleteRequest(`/api/training_invitations/${element.id}`)
                  .then((response) => {
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                  }).catch((reason) => {
                      alert(reason);
                      let mytoast = toast.current ? toast : ownToast;
                      mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
                  })
            });
            getRequest("/api/training_invitations").then((response) => {
              dispatch({type:'load_training_invitations',newTrainingInvitations:response.data});
          });
        }
    
        const reject = () => {
          let mytoast = toast.current ? toast : ownToast;
          mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
    
        const confirm = () => {
            confirmDialog({
                message: 'Prajete si vymazať tento záznam?',
                header: 'Potvrdenie vymazania',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Nie',
                rejectLabel: 'Áno',
                accept: reject,
                reject: accept
            });
        };
    
      const rowClick = (e, row) => {
        console.log(e, row);
     };
    
      const onSelectRow = (data, rows) => {
        setEditFormGauge(data);
          if(data.length === 0){
            setInsertIcon(true);
            setEditIcon(true);
            setCopyIcon(true);
            setDeleteIcon(true);
          }
          else{
            setInsertIcon(false);
            setEditIcon(false);
            setCopyIcon(false);
            setDeleteIcon(false);
          }
      };

      function onEditRow(invitation){
        dispatch({type:'create_new_tab',name:'Upraviť pozvánku',page:'TrainingInvitationAdd',props:{invitation: invitation,type:'edit'},mask:'skol028'})
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

      function EmployeesFormatter(props) {
        const rowData = props.cell.getRow().getData();
        let initials="";
        return rowData.employees.map((item) => {
          const allNames = item.name.trim().split(' ');
          if(allNames.length == 3){
            initials = allNames.reduce((acc, curr, index) => {
              if(index === allNames.length - 2 || index === allNames.length - 1){
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
              }
              return acc;
            }, '');
          }
          else{
            initials = allNames.reduce((acc, curr, index) => {
              if(index === 0 || index === allNames.length - 1){
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
              }
              return acc;
            }, '');
          }
          return <Avatar title={item.name} label={initials} shape="circle" style={{ backgroundColor: '#1d4ed8', color: '#ffffff',marginRight: '5px'}} />;
        });
      }
  
      function employeesHeaderFilter(headerValue, rowValue, rowData, filterParams){
        let employees = rowData.employees.map(item => item.name);
        for(let i = 0;i<employees.length;i++){
          let filter = headerValue.toLowerCase();
          let value = employees[i].toLowerCase();
          if(value.includes(filter)){
            return true;
          }
        }
        return false;
      }

      function StateFormatter(props) {
        const rowData = props.cell.getRow().getData();
        if(new Date(rowData.invitation_date) < new Date()) return "Neaktívna";
        else return "Aktívna";
      }

      function stateHeaderFilter(headerValue, rowValue, rowData, filterParams){
        let state = "";
        if(new Date(rowData.invitation_date) < new Date()) state="Neaktívna";
        else state="Aktívna";
        return headerValue.toUpperCase() == state.toUpperCase();
      }

      function dateHeaderFilter(headerValue, rowValue, rowData, filterParams){
        if(filterParams.name=="invitation_date"){
          return DateTime.fromISO(rowData.invitation_date).toFormat("dd.MM.yyyy").includes(headerValue);
        }
        if(filterParams.name=="invitation_time"){
          return DateTime.fromISO(rowData.invitation_time).toFormat("HH:mm").includes(headerValue);
        }
      }
  

      const [draggingItem,setDraggingItem] = useState(null); 
      const [columns,setColumns] = useState([
        { title: '', field: 'id',formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
        { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Názov', field: 'name',headerFilter:"input",minWidth: 200 },
        { title: 'Dátum', field: 'invitation_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"invitation_date"},width:200,formatter: function (cell, formatterParams) {
            const { outputFormat = "dd.MM.yyyy" } = formatterParams;
            let value = cell.getValue();
            return DateTime.fromISO(value).toFormat(outputFormat);
          } 
        },
        { title: 'Čas', field: 'invitation_time',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"invitation_time"},width:200,formatter: function (cell, formatterParams) {
            const { outputFormat = "HH:mm" } = formatterParams;
            let value = cell.getValue();
            return DateTime.fromISO(value).toFormat(outputFormat);
          } 
        },
        { title: 'Školenie', field: 'training.name',headerFilter:"input",minWidth: 200 },
        { title: 'Stav', field: 'customs_state',headerFilter:"list", headerFilterParams:{values:{"Aktívna":"Aktívna","Neaktívna":"Neaktívna"}, clearable:true},headerFilterFunc:stateHeaderFilter,formatter: reactFormatter(<StateFormatter/>),minWidth: 200 },
        { title: 'Škol. firma', field: 'training_company.name',headerFilter:"input",minWidth: 200 },
        { title: 'Školiteľ', field: 'contact.name',headerFilter:"input",minWidth: 200 },
        { title: 'Zamestnanci', field: 'custom_employees',headerFilter:"input",headerFilterFunc:employeesHeaderFilter,minWidth: 200,formatter: reactFormatter(<EmployeesFormatter/>),resizable:false },
      ]);

      async function scrollToFirst(){
        ref.current.scrollToRow(ref.current.getRows("range")[0].getData().id, "top", true);
      }
    
      async function scrollUp(){
        let first_visible = ref.current.getRows("visible")[0].getData().id - (ref.current.getRows("visible").length-2);
        ref.current.scrollToRow(first_visible < 1 ? 1 : first_visible, "top", false);
      }
    
      async function scrollDown(){
          let last_visible = ref.current.getRows("visible")[ref.current.getRows("visible").length-1].getData().id;
          ref.current.scrollToRow(last_visible, "top", true);
      }
    
      async function scrollToLast(){
        ref.current.scrollToRow(ref.current.getRows("range")[ref.current.getRows("range").length-1].getData().id, "bottom", true);
      }

      function addNewTrainingCompany(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: null,type:'add'},mask:'skol028'})
      }
      function copyTrainingCompany(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: ref.current.getSelectedData()[0],type:'add'},mask:'skol028'})
      }
      function editTrainingCompany(){
        dispatch({type:'create_new_tab',name:'Upraviť pozvánku',page:'TrainingInvitationAdd',props:{invitation: ref.current.getSelectedData()[0],type:'edit'},mask:'skol028'})
      }
    
      function handleDragStart(e, item){ 
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', ''); 
      }; 
  
      function handleDragEnd(){ 
        setDraggingItem(null);
      }; 
  
      function handleDragOver(e){ 
          e.preventDefault(); 
      }; 
  
      function handleDrop(e, targetItem){ 
          if (!draggingItem) return; 
  
          const currentIndex = columns.indexOf(draggingItem); 
          const targetIndex = columns.indexOf(targetItem); 
  
          if (currentIndex !== -1 && targetIndex !== -1) { 
            columns.splice(currentIndex, 1); 
            columns.splice(targetIndex, 0, draggingItem); 
             
          } 
          ref.current.setColumns(columns);
      }; 
    
    function setMask(item,value){
      const columnVisible = {...columnsVisible};
     columnVisible[item] = value;
     console.log(columnVisible);
      for(const val in columnVisible) {
        if(columnVisible[val]==false){
          ref.current.hideColumn(`${val}`);
        }
        else{
          ref.current.showColumn(`${val}`);
        }
      }
      setColumnsVisible(columnVisible);
    }
  
    const itemRenderer = (item, options) => (
      <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
          <span className={`${item.icon} text-primary`} />
          <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
          {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    );
  
    const [expandedKeys, setExpandedKeys] = useState({});
  
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
          setExpandedKeys({});
      }
    };
  
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
              {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingInvitationList","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingInvitationList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_4',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];
  
    function showChosenNotifications(){
      let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "TrainingInvitationList"));
      if(db_chosen_notifications?.length>0){
        setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
      }
      else{
        setChosenNotifications([]);
      }
      setNotificationVisible(true);
    }
  
    function saveChosenNotifications(){
        let formData = new FormData();
        formData.append("type","training_invitations");
        formData.append("data", JSON.stringify(chosenNotifications));
        postRequest('/api/chosen_notifications', formData,true)
        .then((response) => {
            if(response.status == 201){
              dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
            }else{
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
            }
        }).catch((reason) => {
              console.log(reason);
              alert("Daný úkon sa nepodaril!");
        });
        setNotificationVisible(false);
    }

    function changeMarkings(value){
      if(value){
        const columnVisible = {...columnsVisible};
        for(let val in columnVisible) {
          if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
            columnVisible[val]=true;
            ref.current.showColumn(`${val}`);
          }
        };
        setColumnsVisible(columnVisible);
      }
      else{
        const columnVisible = {...columnsVisible};
        for(let val in columnVisible) {
          if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
            columnVisible[val]=false;
            ref.current.hideColumn(`${val}`);
          }
        };
        setColumnsVisible(columnVisible);
      }
    }

    function previewFilter(data, filterParams){
      //data - the data for the row being filtered
      //filterParams - params object passed to the filter
      if(filterParams.time_period=="03"){
        return (new Date(data.invitation_date) <= new Date(new Date().setDate(new Date().getDate() + 3)) && new Date(data.invitation_date) > new Date());
      }
      else if(filterParams.time_period=="37"){
        return new Date(data.invitation_date) <= new Date(new Date().setDate(new Date().getDate() + 7)) && new Date(data.invitation_date) > new Date(new Date().setDate(new Date().getDate() + 3));
      }
      else if(filterParams.time_period=="7m"){
        return new Date(data.invitation_date) > new Date(new Date().setDate(new Date().getDate() + 7));
      }
      return false;
    }
    
    return(
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button onClick={() => addNewTrainingCompany()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editTrainingCompany()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyTrainingCompany()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Zoznam pozvánok","TrainingInvitationList")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="fast"><button onClick={() => setFastView("TrainingInvitationList",columnsVisible,columns)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
      </div>
      <div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    
    <div ref={elementRef}>
      <ReactTabulator
      data={state.training_invitations} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
          if(preview!=null){
            ref.current.setFilter(previewFilter, {time_period:preview});
          }
          let mask = state.user_mask_filters.filter(mask => mask.page=="TrainingInvitationList");
            if(mask.length>0){
              let attributes = [];
              try {
                attributes = JSON.parse(mask[0].attributes)
              } catch (e) {
                attributes = []
              }
              if(attributes?.length>1)ref.current.setColumnLayout(attributes);
              let filters = [];
              try {
                filters = JSON.parse(mask[0].filters);
              } catch (e) {
                filters = []
              }
              filters.forEach(filter => {
                ref.current.setHeaderFilterValue(filter.field,filter.value);
              });
              let json_columns;
              try {
                json_columns = JSON.parse(mask[0].columns);
                setColumnsVisible(json_columns);
              } catch (e) {
                json_columns = []
              }
              for(let column in json_columns){
                if(json_columns[column]==false)ref.current.hideColumn(column);
                else ref.current.showColumn(column);
              }
            }
        },
        columnMoved:() => {
          setUserMaskFilter("TrainingInvitationList","column_move",null,null,ref.current.getColumnLayout());
        }
      }}
      options={{
        renderHorizontal:"virtual",movableColumns: true,rowHeight:42,
        printAsHtml:true,printHeader:"<h1>Zoznam pozvánok",printRowRange:"active",
      }}/>
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
          <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1" 
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingInvitationList","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
          <InfinitySpin 
          width='208'
          color="#000"
          />
          <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nový záznam' value='Nový záznam' checked={chosenNotifications.includes('Nový záznam')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nový záznam' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
      </div> 
        </>
    )
}