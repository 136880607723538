import React,{useContext, useState, useRef,useEffect} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, InviteDate, InviteTime, InviteLocation, TrainingTheme} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { DateTime } from "luxon";

export default function TrainingInvitationAdd({invitation,type}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const ref = useRef(null);
    const contentRef = useRef(null);
    const [formType,setFormType] = useState(type ? type : "add");

    const [showInvitationPreview,setShowInvitationPreview] = useState(false);
    const [procesedText,setProcesedText] = useState("");

    const [formInvitation,setFormInvitation] = useState(invitation);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState(state.training_types);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);


    useEffect(() => {
        // Update the document title using the browser API
        if(formInvitation!=null){
            console.log(formInvitation);
            filterTrainings(formInvitation.training?.department_id);
        }
    },[formInvitation]);

    function addNewInvitation(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: null,type:'add'},mask:'skol028'})
    }
    function copyInvitation(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: formik.values,type:'add'},mask:'skol028'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formInvitation==null?{
            name: '',invitation_date: '',invitation_time:'',location:'',training_id:'',training_company_id:'',contact_id:'',training_invitation_template_id:'',content:'',notifications:[],email:true,phone:false,employees:[],}
            :{
                id:formInvitation.id,name:formInvitation.name,invitation_date:formInvitation.invitation_date,invitation_time:formInvitation.invitation_time,location:formInvitation.location,training_id:formInvitation.training_id,
                training_company_id:formInvitation.training_company_id,contact_id:formInvitation.contact_id,training_invitation_template_id:formInvitation.training_invitation_template_id,content:formInvitation.content,
                notifications:formInvitation.notifications ? formInvitation.notifications.split(',').map((notification) => ({name:notification})) : [],
                email:formInvitation.email==1?true:false,phone:formInvitation.phone==1?true:false,employees:formInvitation.employees.map((employee) => ({name:employee.name,id:employee.id}))
            },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="notifications"){
                    formData.append("notifications", values.notifications.map(condition => condition.name));
                }
                else if(value=="employees"){
                    formData.append("employees", values.employees.map(employee => employee.id));
                }
                else  formData.append(value, values[value] != null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/training_invitations', formData,true)
                .then((response) => {
                    setFormType("edit");
                    getRequest("/api/training_invitations").then((response) => {
                        dispatch({type:'load_training_invitations',newTrainingInvitations:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_invitations/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/training_invitations").then((response) => {
                        dispatch({type:'load_training_invitations',newTrainingInvitations:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function insertMyText(textToInsert){
        let cursorPosition = contentRef.current.selectionStart;
        let textBeforeCursorPosition = contentRef.current.value.substring(0, cursorPosition);
        let textAfterCursorPosition = contentRef.current.value.substring(cursorPosition, contentRef.current.value.length);
        contentRef.current.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        contentRef.current.selectionStart = contentRef.current.selectionStart + textToInsert.length;
        contentRef.current.focus();
        formik.setFieldValue("content", contentRef.current.value);
      }

      const dayTimePeriod = [
        {name: '1 deň pred'},
        {name: '2 dní pred'},
        {name: '3 dní pred'},
        {name: '4 dní pred'},
        {name: '5 dní pred'},
        {name: '6 dní pred'},
        {name: '7 dní pred'},
    ];

    function previewInvitation(){
        setShowInvitationPreview(true);
        setProcesedText(formik.values.content.replace("[Dátum]",DateTime.fromISO(formik.values.invitation_date).toFormat("dd.MM.yyyy")).replace("[Čas]",DateTime.fromISO(formik.values.invitation_time).toFormat("HH:MM")).replace("[Miesto]",formik.values.location).replace("[Názov školenia]",state.training_types.filter((training) => training.id == formik.values.training_id)[0]?.name));
    }

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        if(departmentId=="")setTrainings(state.training_types);
        else setTrainings(state.training_types
        .filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    function changeTemplate(template_id){
        formik.setFieldValue('training_invitation_template_id',template_id);
        if(template_id){
            let my_template = state.training_invitation_templates.filter((inv_template) => inv_template.id == template_id)[0];
            if(my_template){
                formik.setFieldValue('content',my_template?.content);
                contentRef.current.value = my_template.content;
                contentRef?.current?.focus();
            }
        }
        else{
            formik.setFieldValue('content',"");
            contentRef.current.value = "";
            contentRef?.current?.focus();
        }
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewInvitation()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyInvitation()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová pozvánka","TrainingInvitationAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingInvitationAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-y-auto h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÁ POZVÁNKA
                </h1>
                <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov
                        </label>
                        <input
                            name='name'
                            type="text" required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="invitation_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum 
                        </label>
                        <input
                            name='invitation_date'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.invitation_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="invitation_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas
                        </label>
                        <input
                            name='invitation_time'
                            type="time" required
                            onChange={formik.handleChange} value={formik.values.invitation_time}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="location"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Miesto 
                        </label>
                        <input
                            name='location'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.location}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                            <div>
                        <label
                            htmlFor="trainingDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie školenia
                        </label>
                        <select 
                        required
                        name='trainingDepartment'
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                        <option value="">Vyber oddelenie</option>
                        {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="training_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Názov školenia
                        </label>
                        <select required
                        name='training_id'
                        onChange={formik.handleChange} value={formik.values.training_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber školenie</option>
                        {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                        ))}
                        </select>
                    </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="training_company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Školiaca firma
                        </label>
                        <select 
                        name='training_company_id'
                        onChange={formik.handleChange} value={formik.values.training_company_id}

                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber škol. firmu</option>
                          {state.training_companies.map((training_company) => (
                            <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="contact_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Školiteľ 
                        </label>
                        <select 
                        name='contact_id'
                        onChange={formik.handleChange} value={formik.values.contact_id}

                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber školiteľa</option>
                          {state.training_company_contacts.filter(contact => contact.training_company_id == formik.values?.training_company_id).map((contact) => (
                            <option key={contact.id} value={contact.id}>{contact.name}</option>
                          ))}
                        </select>
                        </div>
                    </div>
                        <div>
                            <label
                                for="employees"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Zamestnanci
                            </label>
                            <MultiSelect value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                            options={state.employees.map((employee) => 
                                ({name:employee.name,id:employee.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnancov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div>
                        <label
                            htmlFor="training_invitation_template_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Vzor pozvánky
                        </label>
                        <select 
                        name='training_invitation_template_id'
                        onChange={(e) => changeTemplate(e.target.value)} value={formik.values.training_invitation_template_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Bez vzoru</option>
                          {state.training_invitation_templates.map((template) => (
                            <option key={template.id} value={template.id}>{template.name}</option>
                          ))}
                        </select>
                        </div>
                        <div className='mb-2'>
                        <label
                            htmlFor="content"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Obsah pozvánky 
                        </label>
                        <div className='w-full h-12 flex flex-row justify-start gap-1 px-1 py-1 text-white'>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Dátum]")}><InviteDate/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Čas]")}><InviteTime/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Miesto]")}><InviteLocation/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Názov školenia]")}><TrainingTheme/></button></div>
                        </div>
                        <InputTextarea ref={contentRef} autoResize name='content' required onChange={formik.handleChange} value={formik.values.content} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-4'>
                            <label className='block text-md font-semibold text-gray-800 mr-2'>Odoslanie upozornenia:</label>
                            <MultiSelect required value={formik.values.notifications} onChange={(e) => formik.setFieldValue("notifications",e.value)} options={dayTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='flex flex-col'>
                            <div className="flex justify-content-center mb-2">
                                <label className='block text-md font-semibold text-gray-800 mr-2'>Email:</label>
                                <InputSwitch checked={formik.values.email} onChange={(e) => formik.setFieldValue("email",e.value)} />
                            </div>
                            <div className="flex justify-content-center">
                            <label className='block text-md font-semibold text-gray-800 mr-2'>SMS:</label>
                                <InputSwitch checked={formik.values.phone} onChange={(e) => formik.setFieldValue("phone",e.value)} />
                            </div>
                        </div>
                        <div>
                            <button type='button' onClick={() => previewInvitation()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Zobraziť náhľad</button>
                        </div>
                    
                    <button type='submit' ref={submitRef}></button>
            </div>
            {showInvitationPreview && <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <label className='block text-md font-semibold text-gray-800 mr-2'>Náhľad pozvánky:</label>
                <InputTextarea autoResize name='content' required value={procesedText} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            </div>}
            </div>
            </div>
             </form>
            </div>
            </> 
            );

}