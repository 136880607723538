/* actions for loading the necessary data from the database into the global state */
import { menuItems } from '../pages/recursive_menu/menuItems';
import { initialState } from './DatabaseStore';

export function DatabaseReducer(state, action) {
    const hiddenTabs = ["SupportTicketAdd", "SupportTicketList",];
    switch (action.type) {
      case 'load_departments': {
        return {
          ...state,
          departments: action.newDepartments,
        };
      }
      case 'load_employees': {
        return {
          ...state,
          employees: action.newEmployees
        };
      }
      case 'load_customers': {
        return {
          ...state,
          customers: action.newCustomers
        };
      }
      case 'load_error_types': {
        return {
          ...state,
          error_types: action.newErrorTypes
        };
      }
      case 'load_groups': {
        return {
          ...state,
          groups: action.newGroups
        };
      }
      case 'load_suppliers': {
        return {
          ...state,
          suppliers: action.newSuppliers
        };
      }
      case 'load_preventions': {
        return {
          ...state,
          preventions: action.newPreventions
        };
      }
      case 'load_summary_emails': {
        return {
          ...state,
          summary_emails_otk: action.newSummaryEmails.filter(email => email.type == 'otk'),
          summary_emails_painting: action.newSummaryEmails.filter(email => email.type == 'painting'),
          summary_emails_calibration: action.newSummaryEmails.filter(email => email.type == 'calibration'), 
          summary_emails_training: action.newSummaryEmails.filter(email => email.type == 'training') 
        };
      }
      case 'load_offenders': {
        return {
          ...state,
          offenders: action.newOffenders
        };
      }
      case 'load_error_records': {
        return {
          ...state,
          error_records: action.newErrorRecords,
          error_records_int: action.newErrorRecords.filter(error_record => error_record.type == 'Interná chyba'),
          error_records_ext: action.newErrorRecords.filter(error_record => error_record.type == 'Externá chyba'),
          error_records_rek: action.newErrorRecords.filter(error_record => error_record.type == 'Reklamácia'),
        };
      }
      case 'load_documentation': {
        return {
          ...state,
          documentation: action.newDocumentation
        };
      }
      case 'load_self_control': {
        return {
          ...state,
          self_control: action.newSelfControl
        };
      }
      case 'load_measurement_protocol': {
        return {
          ...state,
          measurement_protocol: action.newMeasurementProtocol
        };
      }
      case 'load_ndt_protocol': {
        return {
          ...state,
          ndt_protocol: action.newNDTProtocol
        };
      }
      case 'load_annealing_protocol': {
        return {
          ...state,
          annealing_protocol: action.newAnnealingProtocol
        };
      }
      case 'load_measure_complexities': {
        return {
          ...state,
          measure_complexities: action.newMeasureComplexities
        };
      }
      case 'load_priority_departments': {
        let final_employees = [];
        for(let i = 0;i<action.newPriorityDepartments.length;i++){
          var abbreviation_employees = [];
          
          for(let j = 0;j<action.newPriorityDepartments[i].departments.length;j++){
            abbreviation_employees = [...abbreviation_employees, ...action.newPriorityDepartments[i].departments[j].employees];
          }
          final_employees = [...final_employees,{
            abbreviation: action.newPriorityDepartments[i].abbreviation,
            id: action.newPriorityDepartments[i].id,  
            employees: abbreviation_employees
          }];
        }
        return {
          ...state,
          priority_departments: action.newPriorityDepartments,
          priority_departments_employees: final_employees,
        };
      }
      case 'load_priority_loads': {
        return {
          ...state,
          priority_automatic_loads: action.newPriorityLoads.filter(element => element.priority_type == 'automatic'),
          priority_manually_loads: action.newPriorityLoads.filter(element => element.priority_type == 'manually'),
        };
      }
      case 'priority_departments_reload': {
        return {
          ...state,
          priority_departments_reload: action.value
        };
      }
      case 'load_priorities': {
        return {
          ...state,
          priorities: action.newPriorities
        };
      }
      case 'priorities_reload': {
        return {
          ...state,
          priorities_reload: action.value
        };
      }
      case 'load_control_records': {
        return {
          ...state,
          control_records: action.newControlRecords
        };
      }
      case 'load_users': {
        return {
          ...state,
          users: action.newUsers
        };
      }
      case 'users_reload': {
        return {
          ...state,
          users_reload: action.value
        };
      }
      case 'load_roles': {
        return {
          ...state,
          roles: action.newRoles
        };
      }
      case 'load_favorites': {
        return {
          ...state,
          favorites: action.newFavorites
        };
      }
      case 'load_fast_view': {
        return {
          ...state,
          fast_view: action.newFastView
        };
      }
      case 'load_user_mask_filters': {
        return {
          ...state,
          user_mask_filters: action.newUserMaskFilters
        };
      }
      case 'load_chosen_notifications': {
        return {
          ...state,
          chosen_notifications: action.newChosenNotifications
        };
      }
      case 'login_user': {
        return {
          ...state,
          user: action.newUser
        };
      }
      case 'load_last_used': {
        let last_used = JSON.parse(localStorage.getItem("favorites"));
        if(last_used==undefined)last_used=[];
        last_used = last_used.filter(item => item.user_id === state.user.id);
        if(last_used.length>10)last_used = last_used.slice(0,10);
        return {
          ...state,
          last_used: last_used
        };
      }
      case 'load_painting_intervals':{
        return {
          ...state,
          painting_intervals: action.newPaintingIntervals
        }
      }
      case 'load_painting_conditions':{
        return {
          ...state,
          painting_conditions: action.newPaintingConditions
        }
      }
      case 'load_painting_time_conditions':{
        return {
          ...state,
          painting_time_conditions: action.newPaintingTimeConditions
        }
      }
      case 'load_painting_employee_conditions':{
        return {
          ...state,
          painting_employee_conditions: action.newPaintingEmployeeConditions
        }
      }
      case 'load_weather':{
        return {
          ...state,
          weather: action.newWeather
        }
      }
      case 'load_gauges':{
        return {
          ...state,
          gauges: action.newGauges
        }
      }
      case 'load_gauge_calibrations':{
        return {
          ...state,
          gauge_calibrations: action.newGaugeCalibrations
        }
      }
      case 'load_calibration_companies':{
        return {
          ...state,
          calibration_companies: action.newCalibrationCompanies
        }
      }
      case 'load_calibration_company_certificates':{
        return {
          ...state,
          calibration_company_certificates: action.newCalibrationCompanyCertificates
        }
      }
      case 'load_training_companies': {
        return {
          ...state,
          training_companies: action.newTrainingCompanies
        }
      }
      case 'load_training_company_contacts': {
        return {
          ...state,
          training_company_contacts: action.newTrainingCompanyContacts
        }
      }
      case 'load_training_types': {
        return {
          ...state,
          training_types: action.newTrainingTypes
        }
      }
      case 'load_training_cards': {
        return {
          ...state,
          training_cards: action.newTrainingCards
        }
      }
      case 'load_training_employees': {
        return {
          ...state,
          training_employees: action.newTrainingEmployees
        }
      }
      case 'load_training_templates': {
        return {
          ...state,
          training_templates: action.newTrainingTemplates
        }
      }
      case 'load_training_company_certificates':{
        return {
          ...state,
          training_company_certificates: action.newTrainingCompanyCertificates
        }
      }
      case 'load_training_invitations':{
        return {
          ...state,
          training_invitations: action.newTrainingInvitations
        }
      }
      case 'load_training_invitation_templates':{
        return {
          ...state,
          training_invitation_templates: action.newTrainingInvitationTemplates
        }
      }
      case 'load_support_tickets':{
        return {
          ...state,
          support_tickets: action.newSupportTickets
        }
      }
      case 'create_new_tab': {
        const newTab = {
          value: `${state.tabIndex}`,
          label: action.name,
          mask: action?.mask || "notfind0001"
        }
        const newPanel = {
          value: `${state.tabIndex}`,
          child: action.page,
          props: action.props || null,
        }
        let all_last_used = JSON.parse(localStorage.getItem("favorites"));
        if(all_last_used==undefined)all_last_used=[];
        let my_last_used = all_last_used.filter(item => item.user_id === state.user.id);
        let not_my_last_used = all_last_used.filter(item => item.user_id !== state.user.id);
        my_last_used = my_last_used.filter(item => item.name !== action.name && item.page !== action.page);
        if(my_last_used.length>10)my_last_used = my_last_used.slice(0,10);
        var new_last_used,new_my_last_used;
        if(newPanel?.props?.not_last_used){
          new_last_used = [...my_last_used,...not_my_last_used];
          new_my_last_used = [...my_last_used];
        }
        else{
          new_last_used = [{name:action.name,page:action.page,user_id: state.user.id},...my_last_used,...not_my_last_used];
          new_my_last_used = [{name:action.name,page:action.page,user_id: state.user.id},...my_last_used];
        }
        
        localStorage.setItem("favorites",JSON.stringify(new_last_used));
        return {
          ...state,
          tabs: state.tabs.concat(newTab),
          panels: state.panels.concat(newPanel),
          tabIndex: state.tabIndex+1,
          selectedTab: `${state.tabIndex}`,
          selectedMask: newTab.mask,
          last_used: new_my_last_used
        };
      }
      case 'close_tab': {
        //var tabs = state.tabs.filter(x => hiddenTabs.indexOf(x.label) == -1);
        //var panels = state.panels.filter(x => hiddenTabs.indexOf(x.child) == -1);
        var tabs = state.tabs.filter(x => x.value !== action.id);
        var panels = state.panels.filter(x => x.value !== action.id);
        var selectedTab = ""+(panels[panels.length-1].value) + "";
        if(selectedTab=='2')selectedTab='1';
        return {
          ...state,
          tabs: tabs,
          panels: panels,
          selectedTab: selectedTab,
          selectedMask: tabs.filter(x => x.value==selectedTab)[0]?.mask
        };
      }
      case 'change_tab': {
        //const tabs = state.tabs.filter(x => hiddenTabs.indexOf(x.label) == -1);
        //const panels = state.panels.filter(x => hiddenTabs.indexOf(x.child) == -1);
        //console.log(tabs,panels);
        return {
          ...state,
          //tabs: tabs,
          //panels: panels,
          selectedTab: action.name,
          selectedMask: state.tabs.filter(x => x.value==action.name)[0]?.mask || 'notfind001'
        };
      }
      /*case 'add_to_favorites': {
        var found = null;
        function search(menu,title){
            menu.forEach(x => {
              if(x.children){
                search(x.children,title);
              }
              else{
                if(x.page===title){
                  found=x;
                  return;
                }
              }
            })
        }
        const filtered_tab = state.panels.filter(x => x.value === state.selectedTab)[0];
        search(menuItems,filtered_tab.child);
        console.log("SAVE VALUE ",found);
        return {
          ...state,
          favorites: [...new Set(state.favorites.concat(found))]
        };
      }*/

      case 'customers_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          customers_reload: action.value
        };
      }
      case 'suppliers_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          suppliers_reload: action.value
        };
      }
      case 'error_records_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          error_records_reload: action.value
        };
      }
      case 'departments_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          departments_reload: action.value
        };
      }
      case 'employees_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          employees_reload: action.value
        };
      }
      case 'roles_reload': {
        //console.log("SET RELOAD: ",action.value);
        return {
          ...state,
          roles_reload: action.value
        };
      }
      case 'painting_intervals_reload': {
        return {
          ...state,
          painting_intervals_reload: action.value
        }
      }
      case 'painting_conditions_reload': {
        return {
          ...state,
          painting_conditions_reload: action.value
        }
      }
      case 'gauges_reload': {
        return {
          ...state,
          gauges: action.value
        }
      }
      case 'gauge_calibrations_reload': {
        return {
          ...state,
          gauge_calibrations: action.value
        }
      }
      case 'calibration_companies_reload': {
        return {
          ...state,
          calibration_companies: action.value
        }
      }
      case 'calibration_company_certificates_reload': {
        return {
          ...state,
          calibration_company_certificates: action.value
        }
      }
      case 'set_initial_state': {
        return initialState;
      }
      case 'load_notifications': {
        return {
          ...state,
          notifications: action.newNotifications,
        };
      }
      case 'add_notification': {
        return {
          ...state,
          notifications: [{id:action.value.id,message: action.value.message,data: action.value.data},...state.notifications],
        };
      }
      case 'clear_notification': {
        return {
          ...state,
          notifications: action.value,
        };
      }
      default:{
        throw Error('Unknown action: ' + action.type);
      }
    }
}
export default DatabaseReducer;