import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest ,deleteRequest} from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,EditRowIcon,DeleteIcon, EditIcon, PaperClipIcon, ActivateIcon, DeactivateIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AuthContext from '../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import UtilContext from '../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import ReactDOMServer from 'react-dom/server';


export default function TrainingCardDetail({employee, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({'training.department.name':true,'training.name':true,initial_date:true,deactivation_date:true,description:true});
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formEmployee,setFormEmployee] = useState(employee);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [visibleCard,setVisibleCard] = useState(false);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [copyIcon,setCopyIcon] = useState(true);
    const [activateIcon,setActivateIcon] = useState(true);
    const [deactivateIcon,setDeactivateIcon] = useState(true);
    const [visibleDeactivate, setVisibleDeactivate] = useState(false);

    const [selectedCard,setSelectedCard] = useState(null);

    const [showTrainingCardAdd, setShowTrainingCardAdd] = useState(false);
    const [showIntervalCalibration, setShowIntervalCalibration] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [viewedImage,setViewedImage] = useState('');


    useEffect(() => {
        // Update the document title using the browser API
        if(formEmployee!=null){
            if(user.permissions.includes("edit training"))setScrollIcons(false);
        }
    },[formEmployee]);

    function addNewTrainingCard(){
        setTrainingDepartment([]);
        let owned = state.training_cards.filter((card) => card.id == formEmployee.id)[0].training_card.map(card => card.training_id);
        setTrainings(state.training_types.filter(training_type => (!owned.includes(training_type.id))));
        setSelectedCard(null);
        setShowTrainingCardAdd(true);
    }

    function editNewTrainingCard(){
        formikUpdate.setValues(ref.current.getSelectedData()[0]);
        filterTrainings(ref.current.getSelectedData()[0].training.department_id,ref.current.getSelectedData()[0].training);
        setVisibleCard(true);
    }

    function hideEditCard(){
        formikUpdate.resetForm();
        setVisibleCard(false);
    }

    const onSelectRow = () => {
        setEditIcon(true);
        setCopyIcon(true);
        setDeleteIcon(true);
        setDeactivateIcon(true);
        setActivateIcon(true);
        if(ref.current.getSelectedData().length === 1){
            if(user.permissions.includes("edit training"))setEditIcon(false);
            if(user.permissions.includes("delete training"))setDeleteIcon(false);
            if(user.permissions.includes("add training")){
                if(ref.current.getSelectedData()[0]._children.length==0)setDeleteIcon(false);
                if(ref.current.getSelectedData()[0].deactivation_date=="" || ref.current.getSelectedData()[0].deactivation_date==null)setDeactivateIcon(false);
                else setActivateIcon(false);
            }
            setSelectedCard(ref.current.getSelectedData()[0]);
        }
        else{
            var deactivated = 0;
            var activated = 0;
            ref.current.getSelectedData().forEach(item => {
                if(item.deactivation_date=="" || item.deactivation_date==null)activated++;
                else deactivated++;
            });
            if(!deactivated && activated)setDeactivateIcon(false);
            else if(deactivated && !activated)setActivateIcon(false);
        }
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues:{
           training_id:'',initial_date:'',description:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("employee_id", employee.id);
            postRequest('/api/training_cards', formData,true)
            .then((response) => {
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    setShowTrainingCardAdd(false);
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    const formikUpdate = useFormik({
        initialValues: selectedCard == null ? {
            training_id:'',initial_date:'',deactivation_date:'',description:''} : selectedCard,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("employee_id", employee.id);
            postRequest(`/api/training_cards/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    setVisibleCard(false);
                    formikUpdate.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    const formikDeactivation = useFormik({
        initialValues:{
           deactivation_date:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let rows_to_activate = [];
            rows_to_activate = ref.current.getSelectedData();
            let formData = new FormData();
            rows_to_activate.forEach((item) => {
                formData.append("cards[]",item.id);
            })
            formData.append("deactivation_date",values.deactivation_date);
            postRequest(`/api/training_cards/deactivate`, formData,true)
            .then((response) => {
                setVisibleDeactivate(false);
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznamy boli úspešne upravené!" });
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                setVisibleDeactivate(false);
                alert(reason);
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo upraviť!', life: 3000 });
            });
        },
    });

    function getIndex(employee_id) {
        return state.training_cards.findIndex(obj => obj.id === employee_id);
    }

    async function scrollToFirst(){
        let my_training_card = state.training_cards[0];
        setFormEmployee(my_training_card);
    }
    
    async function scrollUp(){
        let id = getIndex(formEmployee.id);
        if(id-1>=0){
            setFormEmployee(state.training_cards[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formEmployee.id);
        if(id+1<state.training_cards.length){
            setFormEmployee(state.training_cards[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_cards.length-1;
        let my_error_record = state.training_cards[last];
        setFormEmployee(my_error_record);
    }

    function filterTrainings(departmentId,training=null){
        setTrainingDepartment(departmentId);
        let owned = state.training_cards.filter((card) => card.id == formEmployee.id)[0].training_card.map(card => card.training_id);
        if(departmentId==""){
            setTrainings(state.training_types
            .filter(training_type => (!owned.includes(training_type.id) || training?.id==training_type.id)));
        }
        else{
            setTrainings(state.training_types
            .filter(training_type => training_type.department.id === parseInt(departmentId) && (!owned.includes(training_type.id) || training?.id==training_type.id)));
        }
    }

      function InitialDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData?.initial_date ? DateTime.fromISO(rowData.initial_date).toFormat("dd.MM.yyyy") : "";
      }

      function DeactivationDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData?.deactivation_date ? DateTime.fromISO(rowData.deactivation_date).toFormat("dd.MM.yyyy") : "";
      }

      function onEditRow(training_card){
        formikUpdate.setValues(training_card);
        setSelectedCard(training_card);
        filterTrainings(training_card.training.department_id,training_card.training);
        setVisibleCard(true);
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const acceptActivate = () => {
        let rows_to_activate = [];
        rows_to_activate = ref.current.getSelectedData();
        let formData = new FormData();
        rows_to_activate.forEach((item) => {
            formData.append("cards[]",item.id);
        })
        postRequest(`/api/training_cards/activate`, formData,true)
        .then((response) => {
            getRequest("/api/training_cards").then((response) => {
                dispatch({type:'load_training_cards',newTrainingCards:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznamy boli úspešne upravené!" });
            });
            getRequest("/api/training_employees").then((response) => {
                dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
            });
        }).catch((reason) => {
            alert(reason);
            let mytoast = toast.current ? toast : ownToast;
            mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo upraviť!', life: 3000 });
        });
    }

    const confirmActivate = () => {
        confirmDialog({
            message: 'Prajete si tieto školenia aktivovať?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: acceptActivate
        });
    };

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          deleteRequest(`/api/training_cards/${element.id}`)
          .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            getRequest("/api/training_cards").then((response) => {
                dispatch({type:'load_training_cards',newTrainingCards:response.data});
            });
            getRequest("/api/training_employees").then((response) => {
                dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
            });               
          }).catch((reason) => {
              alert(reason);
              if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 }); 
          })
        }); 
      }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam/y?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            defaultFocus: 'accept',
            reject: accept,
            accept: reject
        });
    };
      
    function dateHeaderFilter(headerValue, rowValue, rowData, filterParams){
        if(filterParams.name=="initial_date"){
            if(rowValue=="")return false;
            return DateTime.fromISO(rowData.initial_date).toFormat("dd.MM.yyyy").includes(headerValue);
        }
        else{
            if(rowValue=="")return false;
            return DateTime.fromISO(rowData.deactivation_date).toFormat("dd.MM.yyyy").includes(headerValue);
        }
    }

    const [columns,setColumns] = useState([
        { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false,resizable:false,print:false},
        { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false,print:false},
        { title: 'Oddelenie školenia', field: 'training.department.name',headerFilter:"select", headerFilterParams:{values:state.departments.map(department => `${department.name}`)},minWidth: 200},
        { title: 'Názov školenia', field: 'training.name',headerFilter:"input",minWidth: 200,},
        { title: 'Prvotné školenie', field: 'initial_date',headerFilter:"input",formatter: reactFormatter(<InitialDate/>),headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"initial_date"},minWidth: 200},
        { title: 'Deaktivácia', field: 'deactivation_date',headerFilter:"input",formatter: reactFormatter(<DeactivationDate/>),headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"deactivation_date"},minWidth: 200},
        { title: 'Popis', field: 'description',headerFilter:"input",minWidth: 200,},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
        setTimeout(() => {
            ref.current.redraw(true);
          }, 0)
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingCardDetail","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingCardDetail","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_4',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","TrainingCardDetail");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor" || columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=value;
              ref.current.showColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
          setTimeout(() => {
            ref.current.redraw(true);
          }, 0)
      }


    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingCard()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editNewTrainingCard()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" disabled={deleteIcon} onClick={confirm}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="activate"><button className="disabled:opacity-25" disabled={activateIcon} onClick={confirmActivate}><ActivateIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="deactivate"><button className="disabled:opacity-25" disabled={deactivateIcon} onClick={() => setVisibleDeactivate(true)}><DeactivateIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Upraviť kalibráciu meradla","TrainingCardDetail")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button onClick={() => setFastView("TrainingCardDetail",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
    <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={10} size={10}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
        <div className="relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2'>
          <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   KARTA ZAMESTNANCA
                </h1>
                <label className="block text-md font-semibold text-gray-800">Por.č.: {formEmployee.id}</label>
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zamestnanca
                        </label>
                        <input
                            name='name'
                            type="text"
                            readOnly value={formEmployee.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Priradené ID
                        </label>
                        <input
                            name='defined_id'
                            type="number"
                            readOnly value={formEmployee.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <div>
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                      <div>
                        <label
                            htmlFor="date_of_entry"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum príchodu
                        </label>
                        <input
                            name='date_of_entry'
                            type="text"
                            readonly value={formEmployee.date_of_entry ? DateTime.fromISO(formEmployee.date_of_entry).toFormat("dd.MM.yyyy") : ""}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="date_of_departure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum odchodu
                        </label>
                        <input
                            name='date_of_departure'
                            type="text"
                            readonly value={formEmployee.date_of_departure ? DateTime.fromISO(formEmployee.date_of_departure).toFormat("dd.MM.yyyy") : ""}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                        <div>
                        <label
                            htmlFor="department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        name='department_id'
                        disabled value={formEmployee.department_id}
                        className='block w-full h-[42px] px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis zamestnanca
                        </label>
                        <input
                            name='description'
                            type="text"
                            readOnly value={formEmployee.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
            </div>
            </div> 
            </div>
             </form>
             </SplitterPanel>
    <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
    <ReactTabulator
      data={state.training_cards.filter((card) => card.id == formEmployee.id)[0].training_card} 
      onRef={(r) => (ref.current = r.current)}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
        renderComplete: () => {
            if(ref.current?.getSelectedData()?.length === 0){
              setEditIcon(true);
              setCopyIcon(true);
              setDeleteIcon(true);
              setActivateIcon(true);
              setDeactivateIcon(true);
            }
          },
        tableBuilt: () => {
            let mask = state.user_mask_filters.filter(mask => mask.page=="TrainingCardDetail");
              if(mask.length>0){
                let attributes = [];
                try {
                  attributes = JSON.parse(mask[0].attributes)
                } catch (e) {
                  attributes = []
                }
                if(attributes?.length>1){
                  ref.current.setColumnLayout(attributes);
                }
                let filters = [];
                try {
                  filters = JSON.parse(mask[0].filters);
                } catch (e) {
                  filters = []
                }
                filters.forEach(filter => {
                  ref.current.setHeaderFilterValue(filter.field,filter.value);
                });
                let json_columns;
                try {
                  json_columns = JSON.parse(mask[0].columns);
                  setColumnsVisible(json_columns);
                  setTimeout(() => {
                    ref.current.redraw(true);
                  }, 0)
                } catch (e) {
                  json_columns = []
                }
                for(let column in json_columns){
                  if(json_columns[column]==false)ref.current.hideColumn(column);
                  else ref.current.showColumn(column);
                }
              }
          },
          columnMoved:() => {
            setUserMaskFilter("TrainingCardDetail","column_move",null,null,ref.current.getColumnLayout());
          }
      }}
      options={{renderHorizontal:"virtual",layout:"fitColumns",rowHeight:30,movableColumns:true,
      printAsHtml:true,printRowRange:"active",
      groupBy:"training.department.name",
      groupHeader:function(value, count, data, group){
        //value - the value all members of this group share
        //count - the number of rows in this group
        //data - an array of all the row data objects in this group
        //group - the group component for the group
            if(value=="----------")return "Všeobecné <span style='color:#666; margin-left:10px;'>(" + count + ")</span>";
            return value + "<span style='color:#666; margin-left:10px;'>(" + count + ")</span>";
      },
      printHeader:function(){
        return ReactDOMServer.renderToStaticMarkup(<div className="grid grid-cols-2 gap-1">
            <div className="flex justify-start items-right">
                    <img src={require('./../../assets/img/logo-legro.png')} style={{ height: '5rem', width: 'auto'}} alt="Legro Logo"/>
            </div>
            <div className="mt-[10px] flex justify-end items-center">
            <h1 style={{fontSize: '1.875rem',lineHeight: '2.25rem',textAlign: 'left'}}>
                KARTA ZAMESTNANCA
            </h1>
            </div>
          <div className="mt-6">
              <div className="md:grid grid-cols-2 gap-1 mb-2">
                <div className='mb-2'>
                  <label
                      htmlFor="name"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Meno zamestnanca
                  </label>
                  <input
                      name='name'
                      type="text"
                      value={formEmployee.name}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
                  <div className='mb-2'>
                  <label
                      htmlFor="defined_id"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Priradené ID
                  </label>
                  <input
                      name='defined_id'
                      type="number"
                      value={formEmployee.defined_id}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"                  />
                  </div>
                  <div>
                  <label
                      htmlFor="department_id"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Oddelenie
                  </label>
                  <select 
                  name='department_id'
                  value={formEmployee.department_id}
                  style={{fontSize: '1rem',width:'100%',height:'42px',padding:'4px 4px 4px 4px',}}
                   className="border rounded-md">
                    <option value="">Vyber oddelenie</option>
                    {state.departments.map((department) => (
                      <option key={department.id} value={department.id}>{department.name}</option>
                    ))}
                  </select>
                  </div>
              </div>
          </div>
          <div className="mt-6">
              <div className="md:grid grid-cols-2 gap-4 mb-2">
                <div className='mb-2'>
                  <label
                      htmlFor="date_of_entry"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Dátum príchodu
                  </label>
                  <input
                      name='date_of_entry'
                      type="text"
                      value={formEmployee.date_of_entry ? DateTime.fromISO(formEmployee.date_of_entry).toFormat("dd.MM.yyyy") : ""}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
                  <div>
                  <label
                      htmlFor="date_of_departure"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Dátum odchodu
                  </label>
                  <input
                      name='date_of_departure'
                      type="text"
                      value={formEmployee.date_of_departure ? DateTime.fromISO(formEmployee.date_of_departure).toFormat("dd.MM.yyyy") : ""}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"                  />
                  </div>
              </div>
              <div className="md:grid grid-cols-2 gap-4 mb-2">
                  <div>
                  <label
                      htmlFor="description"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Popis zamestnanca
                  </label>
                  <input
                      name='description'
                      type="text"
                      readOnly value={formEmployee.description}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
              </div>
          </div>
          </div>);
      }
    }}/>
    </SplitterPanel>
    </Splitter> 
    <Dialog header="Upraviť v karte zamestnanca" visible={visibleCard} style={{ width: '40vw' }} onHide={() => hideEditCard()}>
    <form onSubmit={formikUpdate.handleSubmit}>
                        <div>
                            <label
                                htmlFor="trainingDepartment"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Oddelenie školenia
                            </label>
                            <select 
                            required
                            name='trainingDepartment'
                            onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                            className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                            <option value="">Vyber oddelenie</option>
                            {state.departments.map((department) => (
                                <option key={department.id} value={department.id}>{department.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="training_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                            Názov školenia
                            </label>
                            <select required
                            name='training_id'
                            onChange={formikUpdate.handleChange} value={formikUpdate.values.training_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber školenie</option>
                            {trainings.map((training) => (
                                <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                            </select>
                        </div>   
                        <div>
                            <label
                                htmlFor="initial_date"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Dátum prvotného školenia
                            </label>
                            <input
                                name='initial_date'
                                type="date"
                                required
                                onChange={formikUpdate.handleChange} value={formikUpdate.values.initial_date}
                                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="deactivation_date"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Dátum deaktivácie
                            </label>
                            <input
                                name='deactivation_date'
                                type="date"
                                onChange={formikUpdate.handleChange} value={formikUpdate.values.deactivation_date}
                                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formikUpdate.handleChange} value={formikUpdate.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
            </form>
      </Dialog>
        <Dialog header="Pridať do karty zamestnanca" visible={showTrainingCardAdd} style={{ width: '40vw' }} onHide={() => setShowTrainingCardAdd(false)}>
        <form onSubmit={formik.handleSubmit}>
                        <div>
                            <label
                                htmlFor="trainingDepartment"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Oddelenie školenia
                            </label>
                            <select 
                            required
                            name='trainingDepartment'
                            onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                            className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                            <option value="">Vyber oddelenie</option>
                            {state.departments.map((department) => (
                                <option key={department.id} value={department.id}>{department.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="training_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                            Názov školenia
                            </label>
                            <select required
                            name='training_id'
                            onChange={formik.handleChange} value={formik.values.training_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber školenie</option>
                            {trainings.map((training) => (
                                <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                            </select>
                        </div>   
                        <div>
                            <label
                                htmlFor="initial_date"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Dátum prvotného školenia
                            </label>
                            <input
                                name='initial_date'
                                type="date"
                                required
                                onChange={formik.handleChange} value={formik.values.initial_date}
                                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
            </form>
            </Dialog>
            <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2 || index==9) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingCardDetail","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
          <Dialog header="Deaktivácia" visible={visibleDeactivate} style={{ width: '40vw' }} onHide={() => setVisibleDeactivate(false)}>
            <form onSubmit={formikDeactivation.handleSubmit}>
              <div className="flex flex-columns justify-between">
                <label>
                  Dátum:
                <input
                    value={formikDeactivation.values.deactivation_date} onChange={formikDeactivation.handleChange}
                    name='deactivation_date'
                    type="date" required
                    className="block w-full px-1 py-1 mr-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                /> 
                </label>
                <button type='submit' className='w-fit ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>VYKONAŤ</button>
              </div>
              </form>
            </Dialog>
            </div>
            </> 
            );
} 
