import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';

export default function TrainingCompanyCertificateAdd({certificate}){
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const [formCertificate,setFormCertificate] = useState(certificate);
    const [company,setCompany] = useState("");
    const [certificates,setCertificates] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);

    const [fileList, setFileList] = useState([]);

    function addNewTraining(){
        dispatch({type:'create_new_tab',name:'Nový certifikát',page:'TrainingCompanyCertificateAdd',props:{certificate: null,type:'add'},mask:'skol024'})
      }
      function copyTraining(){
        dispatch({type:'create_new_tab',name:'Nový certifikát',page:'TrainingCompanyCertificateAdd',props:{certificate: formik.values,type:'add'},mask:'skol024'})
      }

    const validate = values => {
        const errors = {};
        return errors;
    };

    const formik = useFormik({
        initialValues: formCertificate==null?{
            start_of_validity: '',end_of_validity: '',description:'',certificate_id:''}
            :formCertificate,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            postRequest('/api/training_company_cert_records', formData,true)
            .then((response) => {
                console.log(response);
                getRequest("/api/training_companies").then((response) => {
                    dispatch({type:'load_training_companies',newTrainingCompanies:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    //if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                    formik.resetForm();
                    setCompany("");
                    setCertificates([]);
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/training_company_certificates").then((response) => {
                    dispatch({type:'load_training_company_certificates',newTrainingCompanyCertificates:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
        },
    });

    function filterCertificates(company_id){
        setCompany(company_id);
        if(company_id=="")setCertificates([]);
        else setCertificates(state.training_companies.filter(company => company.id === parseInt(company_id))[0]?.certificates);
    }

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTraining()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTraining()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová škol. firma","TrainingRecordAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingRecordAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ZAZNAMENAŤ NOVÝ CERTIFIKÁT
                </h1>
                    <div>
                        <label
                            htmlFor="company"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Školiacia firma
                        </label>
                        <select 
                        required
                        name='company'
                        onChange={(e) => filterCertificates(e.target.value)} value={company}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber škol. firmu</option>
                          {state.training_companies.map((company) => (
                            <option key={company.id} value={company.id}>{company.name}</option>
                            ))}
                        </select>
                    </div>
                        <div>
                        <label
                            htmlFor="certificate_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Certifikát škol. firmy
                        </label>
                        <select 
                        required
                        name="certificate_id"
                        onChange={formik.handleChange} value={formik.values.certificate_id} 
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber certifikát</option>
                          {certificates.map((certificate) => (
                            <option key={certificate.id} value={certificate.id}>{certificate.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="start_of_validity"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum platnosti od
                        </label>
                        <input
                            name='start_of_validity'
                            type="date" required
                            onChange={formik.handleChange} value={formik.values.start_of_validity}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="end_of_validity"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum platnosti do
                        </label>
                        <input
                            name='end_of_validity'
                            type="date" required
                            onChange={formik.handleChange} value={formik.values.end_of_validity}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div> 
                        <div>
                        <label
                            htmlFor="files"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Protokol
                        </label>
                          <input
                          accept='image/*,application/pdf'
                          name='files'
                          ref={fileListRef}
                          onChange={handleFileChange}
                          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                          type='file'
                          multiple="multiple"
                          id='file'/>
                        </div>
                        <button type='submit' ref={submitRef}></button>
            </div>
            </div>
            </div> 
             </form>
            </div>
            </> 
            );

}