import React, { useContext,useState, useEffect, useRef  } from "react";
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DatabaseContext } from "../../redux/DatabaseStore";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, CalendarIcon, LinkIcon,EditRowIcon} from "../svg/svgIcons";
import { deleteRequest, getRequest,postRequest,baseURL } from '../../api/ApiController';
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { Toast } from 'primereact/toast';

export default function Documentation(){

  const [state,dispatch] = useContext(DatabaseContext);
  const {user} = useContext(AuthContext);
  const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible} = useContext(UtilContext);

  const [stepBackIcon,setStepBackIcon] = useState(true);
  const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("add otk-section1"));
  const [editIcon,setEditIcon] = useState(true);
  const [deleteIcon,setDeleteIcon] =  useState(true);
  const [favoriteIcon,setFavoriteIcon] = useState(false);
  const [copyIcon,setCopyIcon] = useState(true);
  const [searchIconColor,setSearchIconColor] = useState("");

  const [visibleSearch,setVisibleSearch] = useState(false);
  const [visibleLoading,setVisibleLoading] = useState(false);
  const [searchValue,setSearchValue] = useState('');
  const [scrollValue,setScrollValue] = useState(1);
  const [printVisible,setPrintVisible] = useState(false);
  const [printValue,setPrintValue] = useState("Zoznam");
  const [visibleCalendar,setVisibleCalendar] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [chosenNotifications, setChosenNotifications] = useState([]);

  const [editErrorRecord, setEditErrorRecord] = useState([]);

  useEffect(() => {
    console.log("TOAST: ",toast);
  },[]);

  const ref = useRef(null);
  const elementRef = useRef(null);
  const componentRef = useRef(null);
  const overlayRef = useRef(null);
  const ownToast = useRef(null);
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {componentRef.current.style.display = "block";},
    onAfterPrint: () => {componentRef.current.style.display = "none";},
    content: () => componentRef.current,
  });
  
  function matchAny(data, filterParams) {
    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function dateRange(data, filterParams) {
    console.log(data.created_at,filterParams);
    return moment(filterParams.fromDate).isSameOrBefore(data.created_at.substring(0,10)) && moment(filterParams.toDate).isSameOrAfter(data.created_at.substring(0,10));
  }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    setSearchIconColor("active");
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

  function searchByDate(){
    ref.current.setFilter(dateRange, {fromDate:fromDate,toDate:toDate });
    setVisibleCalendar(false);
  }


  const rowClick = (e, row) => {
    console.log(e, row);
  };

 const onSelectRow = (data, rows) => {
  setEditErrorRecord(data);
  setEditIcon(true);
  setCopyIcon(true);
  setDeleteIcon(true);
  if(data.length !== 0){
    if(user.permissions.includes("add otk-section1") || data[0].contractor_id==user.employee.id)setCopyIcon(false);
    if(user.permissions.includes("edit otk-section2") || data[0].contractor_id==user.employee.id)setEditIcon(false);
    if(user.permissions.includes("delete otk-section2"))setDeleteIcon(false);
  }
};

function onEditRow(record){
  dispatch({type:'create_new_tab',name:'Dokumentácia - detail',page:'DocumentationDetail',props:{measure: record,name:'DOKUMENTÁCIA',type:'edit',not_last_used: true},mask:'otk010'})
};

function EditRowFormatter(props) {
  const rowData = props.cell.getRow().getData();
  return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
}

const [draggingItem,setDraggingItem] = useState(null);   
const [columns,setColumns] = useState([
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
    {  title: '', field: 'id',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Zakázka', field: 'order_number',headerFilter:"input",widthGrow:1},
    { title: 'ID Zamestnanca', field: 'contractor.defined_id',headerFilter:"input",widthGrow:1},
    { title: 'Počet súborov', field: 'count',headerFilter:"input",widthGrow:1 },
    { title: 'Dátum', field: 'created_at',headerFilter:"input",width:200,formatter: function (cell, formatterParams) {
        const { outputFormat = "dd.MM.yyyy HH:mm" } = formatterParams;
        let value = cell.getValue();
        return DateTime.fromISO(value).toFormat(outputFormat);
      } 
    },
  ]);

  async function scrollToFirst(){
    let res = state.error_records_int[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.error_records_int[scrollValue-visible_rows+1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.error_records_int[0].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.error_records_int[scrollValue+visible_rows-1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.error_records_int[scrollValue+visible_rows-1].id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.error_records_int[rows-visible_rows].id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.error_records_int[rows-visible_rows].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }

  function addNewDocRecord(){
    dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: null,name:'DOKUMENTÁCIA',type:'add',not_last_used: true},mask:'otk009'})
  }

  function copyDocRecord(){
    dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: ref.current.getSelectedData()[0],name:'DOKUMENTÁCIA',type:'add',not_last_used: true},mask:'otk009'})
  }

  function editDok(){
    dispatch({type:'create_new_tab',name:'Dokumentácia - detail',page:'DocumentationDetail',props:{measure: ref.current.getSelectedData()[0],name:'DOKUMENTÁCIA',type:'edit',not_last_used: true},mask:'otk010'})
  }


  function confirmPrintDialog(){
    if(printValue=="Zoznam"){
      ref.current.print(false, true);
    }
    else{
      handlePrint();
    }
  }

  function handleDragStart(e, item){ 
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', ''); 
  }; 

  function handleDragEnd(){ 
    setDraggingItem(null);
  }; 

  function handleDragOver(e){ 
      e.preventDefault(); 
  }; 

  function handleDrop(e, targetItem){ 
      if (!draggingItem) return; 

      const currentIndex = columns.indexOf(draggingItem); 
      const targetIndex = columns.indexOf(targetItem); 

      if (currentIndex !== -1 && targetIndex !== -1) { 
        columns.splice(currentIndex, 1); 
        columns.splice(targetIndex, 0, draggingItem); 
         
      } 
      ref.current.setColumns(columns);
  }; 

  const itemRenderer = (item, options) => (
    <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
        <span className={`${item.icon} text-primary`} />
        <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
  );

  const [expandedKeys, setExpandedKeys] = useState({});

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setExpandedKeys({});
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

    const items = [
    {
      key: '0',
      label: 'Akcie',
      icon: 'pi pi-sliders-v',
      template: itemRenderer,
      items: [
          {
            key: '0_4',
            label: 'Notifikácie',
            icon: 'pi pi-envelope',
            command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
            template: itemRenderer,
        },
      ]
    }
  ];

  function showChosenNotifications(){
    let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
    if(db_chosen_notifications?.length>0){
      setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
    }
    else{
      setChosenNotifications([]);
    }
    setNotificationVisible(true);
  }

  function saveChosenNotifications(){
      let formData = new FormData();
      formData.append("type","documentation");
      formData.append("data", JSON.stringify(chosenNotifications));
      postRequest('/api/chosen_notifications', formData,true)
      .then((response) => {
          if(response.status == 201){
            dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
            else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
          }else{
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
            else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
          }
      }).catch((reason) => {
          console.log(reason);
          alert("Daný úkon sa nepodaril!");
      });
      setNotificationVisible(false);
  }

    return (
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewDocRecord()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editDok()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => setPrintVisible(true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center align-center ' data-targetid="calendar"><button onClick={() => setVisibleCalendar(true)}><CalendarIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Dokumentácia","Documentation")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("Documentation",columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
    </IntersectionObserverWrapper>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
  <div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
    <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
      <div ref={elementRef}>
        {/*<ReactTabulator
        data={state.documentation} 
        className='h-[calc(100vh_-_193px)]' //176px
        onRef={(r) => (ref.current = r.current)}
        columns={columns}
        events={{
          rowClick: rowClick,
          rowSelectionChanged: onSelectRow
        }}
        options={{layout:"fitColumns",rowHeight:30,movableColumns:true,
        printAsHtml:true,printHeader:"<h1>Zoznam interných chýb<h1>",printRowRange:"active",
        autoResize:true}}/> */}
        <ReactTabulator
        className='h-[calc(100vh_-_193px)]' //176px
        onRef={(r) => (ref.current = r.current)}
        columns={columns}
        events={{
          rowClick: rowClick,
          rowSelectionChanged: onSelectRow
        }}
        options={{layout:"fitColumns",rowHeight:30,movableColumns:true,
        printAsHtml:true,printHeader:"<h1>Zoznam interných chýb<h1>",printRowRange:"active",
        autoResize:true,
        ajaxURL:baseURL+"/api/measure/documentation", //ajax URL
        progressiveLoad:"load", //enable progressive loading
        progressiveLoadDelay:200, //wait 200 milliseconds between each request
        dataLoaderLoading:"Načítavam...",
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("tokens")).access_token}`,
            },
        }}}/> 
      </div> 
    </div> 
  </div> 
</div>
            <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
              <div className="flex flex-columns">
                
                <input
                    value={searchValue} onChange={e => setSearchValue(e.target.value)}
                    type="text"
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
              </div>
            </Dialog>
            <Dialog header="Hľadaj v zozname podľa dátumu:" visible={visibleCalendar} style={{ width: '40vw' }} onHide={() => setVisibleCalendar(false)}>
              <div className="flex flex-columns justify-between">
                <label>
                  Od:
                <input
                    value={fromDate} onChange={e => setFromDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 mr-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                /> 
                </label>
                <label>
                  Do:
                <input
                    value={toDate} onChange={e => setToDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                </label>
                <button onClick={() => searchByDate()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
              </div>
            </Dialog>
            <Dialog header="Tlačiť ako" visible={printVisible} style={{ width: '40vw' }} onHide={() => setPrintVisible(false)}>
        <div className="flex flex-columns">
          <select 
          value={printValue} onChange={e => setPrintValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option value="Zoznam">Zoznam</option>                      
          </select>
          <button onClick={() => confirmPrintDialog()} className='w-20 ml-8 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>TLAČ</button>
        </div>
      </Dialog>
      {/* button to trigger printing of target component */}

        {/* component to be printed */}
        <div ref={componentRef} style={{display:'none'}}>
          {editErrorRecord.map((error_record) => (
                             <EightDreport key={error_record.id} error_record={error_record}/>
          ))}
        </div>
        <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nový protokol' value='Nový protokol' checked={chosenNotifications.includes('Nový protokol')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nový protokol' className="ml-2">Nový protokol</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
</div> 
</>
    )

}

/*
<table
          class="min-w-full border text-left text-sm font-light bg-white dark:border-neutral-500">
          <thead class="font-medium border-b bg-gray-400 dark:border-neutral-500">
            <tr>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Meno oddelenia
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Hod. sadzba
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Čislo oddelenia
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b dark:border-neutral-500">
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                1
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                Mark
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                Otto
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
                </a>
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                </svg>
                </a>
              </td>
            </tr>
            <tr class="border-b dark:border-neutral-500">
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                2
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 dark:border-neutral-500">
                Jacob
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                Thornton
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a></td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg></a></td>
            </tr>
            <tr class="border-b dark:border-neutral-500">
              <form action="Oddelenie.js" method="post"></form>
              
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Zadaj oddelenie"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Hodinová sadzba"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Číslo oddelenia"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M12 18v-6M9 15h6"/>
                </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
*/