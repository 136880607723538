import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest ,deleteRequest} from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,EditRowIcon,DeleteIcon, EditIcon, PaperClipIcon, ActivateIcon, DeactivateIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AuthContext from '../../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import '../../../assets/css/style.css';
import '../../../assets/css/iconsmenu.css'
import UtilContext from '../../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';

export default function TrainingTemplateRecordAdd({training_template, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({'training.department.name':true,'training.name':true});
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formTemplate,setFormTemplate] = useState(training_template);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add calibration"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit calibration"))?false:true);
    const [visibleCard,setVisibleCard] = useState(false);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [copyIcon,setCopyIcon] = useState(true);

    const [selectedCard,setSelectedCard] = useState(null);

    const [showTemplateRecordAdd, setShowTemplateRecordAdd] = useState(false);

    function addNewTrainingCard(){
        setTrainingDepartment([]);
        let owned = state.training_templates.filter((template) => template.id == formTemplate.id)[0].template_records.map(card => card.training_id);
        setTrainings(state.training_types.filter(training_type => (!owned.includes(training_type.id))));
        setSelectedCard(null);
        setShowTemplateRecordAdd(true);
    }

    function editNewTrainingCard(){
        formikUpdate.setValues(ref.current.getSelectedData()[0]);
        filterTrainings(ref.current.getSelectedData()[0].training.department_id,ref.current.getSelectedData()[0].training);
        setVisibleCard(true);
    }

    function hideEditCard(){
        formikUpdate.resetForm();
        setVisibleCard(false);
    }

    const onSelectRow = () => {
        setEditIcon(true);
        setCopyIcon(true);
        setDeleteIcon(true);
        if(ref.current.getSelectedData().length === 1){
            if(user.permissions.includes("edit training"))setEditIcon(false);
            if(user.permissions.includes("delete training"))setDeleteIcon(false);
            if(user.permissions.includes("add training"))setCopyIcon(false);
            setSelectedCard(ref.current.getSelectedData()[0]);
        }
    }

    function filterTrainings(departmentId,training=null){
        setTrainingDepartment(departmentId);
        let owned = state.training_templates.filter((template) => template.id == formTemplate.id)[0].template_records.map(card => card.training_id);
        if(departmentId==""){
            setTrainings(state.training_types
            .filter(training_type => (!owned.includes(training_type.id) || training?.id==training_type.id)));
        }
        else{
            setTrainings(state.training_types
                .filter(training_type => training_type.department.id === parseInt(departmentId) && (!owned.includes(training_type.id) || training?.id==training_type.id)));
        }
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues:{
            training_id:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("training_template_id",formTemplate.id);
            postRequest('/api/training_template_records', formData,true)
            .then((response) => {
                getRequest("/api/training_templates").then((response) => {
                    dispatch({type:'load_training_templates',newTrainingTemplates:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    setShowTemplateRecordAdd(false);
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    const formikUpdate = useFormik({
        initialValues: selectedCard == null ? {
            training_id:''
        } : selectedCard,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("training_template_id",formTemplate.id);
            postRequest(`/api/training_template_records/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/training_templates").then((response) => {
                    dispatch({type:'load_training_templates',newTrainingTemplates:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    setVisibleCard(false);
                    formikUpdate.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    function getIndex(training_company_id) {
        return state.training_templates.findIndex(obj => obj.id === training_company_id);
    }

    async function scrollToFirst(){
        let my_training_type = state.training_templates[0];
        setFormTemplate(my_training_type);
    }
    
    async function scrollUp(){
        let id = getIndex(formTemplate.id);
        if(id-1>=0){
            setFormTemplate(state.training_templates[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formTemplate.id);
        if(id+1<state.training_templates.length){
            setFormTemplate(state.training_templates[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_templates.length-1;
        let my_training_type = state.training_templates[last];
        setFormTemplate(my_training_type);
    }

      function onEditRow(template_record){
        formikUpdate.setValues(template_record);
        setSelectedCard(template_record);
        filterTrainings(template_record.training.department_id,template_record.training);
        setVisibleCard(true);
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const acceptCalibration = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/training_template_records/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/training_templates").then((response) => {
                  dispatch({type:'load_training_templates',newTrainingTemplates:response.data});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: acceptCalibration
        });
    };
      

    const [columns,setColumns] = useState([
        { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
        { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Oddelenie školenia', field: 'training.department.name',headerFilter:"select", headerFilterParams:{values:state.departments.map(department => `${department.name}`)},widthGrow:3,resizable:false},
        { title: 'Názov školenia', field: 'training.name',minWidth: 200,widthGrow:3,headerFilter:"input",resizable:false},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingTemplateRecordAdd","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingTemplateRecordAdd","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","training_cards");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        if(value){
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor" || columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=true;
              ref.current.showColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
        else{
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor"|| columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=false;
              ref.current.hideColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
      }


    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingCard()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editNewTrainingCard()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" disabled={deleteIcon} onClick={confirm}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Šablóna školenia","TrainingTemplateRecordAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button onClick={() => setFastView("TrainingTemplateRecordAdd",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
        <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={5} size={5}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
        <div className="relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2'>
          <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
          <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ŠABLÓNA ŠKOLENIA
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formTemplate.id}</label>}
                <div>
                    <label
                        htmlFor="name"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Názov šablóny
                    </label>
                    <input 
                        name='name'
                        type="text" readOnly
                        value={formTemplate.name}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>     
                <button className='hidden' type='submit' ref={submitRef}></button> 
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                            <label
                                htmlFor="department_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Oddelenie šablóny
                            </label>
                            <select
                            name='department_id' disabled
                            value={formTemplate.department_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber oddelenie</option>
                            {state.departments.map((department) => (
                                <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                            </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis
                        </label>
                        <textarea
                            name='description'
                            type="text" readOnly
                            value={formTemplate.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>
                    </div>
                </div>  
            </div>
            </div> 
            </div>
             </form>
             </SplitterPanel>
    <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
    <ReactTabulator
      data={state.training_templates.filter((card) => card.id == formTemplate.id)[0].template_records} 
      onRef={(r) => (ref.current = r.current)}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
      }}
      options={{renderHorizontal:"virtual",rowHeight:30,movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Karta zamestnanca<h1>",printRowRange:"active",
      initialSort:[
        {column:"training.department.name", dir:"asc"},
      ]
      }}/>
    </SplitterPanel>
    </Splitter> 
    <Dialog header="Upraviť v šablóne" visible={visibleCard} style={{ width: '40vw' }} onHide={() => hideEditCard()}>
        <form onSubmit={formikUpdate.handleSubmit}>
            <div>
                <label
                    htmlFor="trainingDepartment"
                    className="block text-md font-semibold text-gray-800"
                >
                    Oddelenie školenia
                </label>
                <select 
                required
                name='trainingDepartment'
                onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                <option value="">Vyber oddelenie</option>
                {state.departments.map((department) => (
                    <option key={department.id} value={department.id}>{department.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <label
                    htmlFor="training_id"
                    className="block text-md font-semibold text-gray-800"
                >
                Názov školenia
                </label>
                <select required
                name='training_id'
                onChange={formikUpdate.handleChange} value={formikUpdate.values.training_id}
                className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <option value="">Vyber školenie</option>
                {trainings.map((training) => (
                    <option key={training.id} value={training.id}>{training.name}</option>
                ))}
                </select>
            </div>          
            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
        </form>
    </Dialog>
        <Dialog header="Pridať do šablóny" visible={showTemplateRecordAdd} style={{ width: '40vw' }} onHide={() => setShowTemplateRecordAdd(false)}>
        <form onSubmit={formik.handleSubmit}>
            <div>
                <label
                    htmlFor="trainingDepartment"
                    className="block text-md font-semibold text-gray-800"
                >
                    Oddelenie školenia
                </label>
                <select 
                required
                name='trainingDepartment'
                onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                <option value="">Vyber oddelenie</option>
                {state.departments.map((department) => (
                    <option key={department.id} value={department.id}>{department.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <label
                    htmlFor="training_id"
                    className="block text-md font-semibold text-gray-800"
                >
                Názov školenia
                </label>
                <select required
                name='training_id'
                onChange={formik.handleChange} value={formik.values.training_id}
                className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <option value="">Vyber školenie</option>
                {trainings.map((training) => (
                    <option key={training.id} value={training.id}>{training.name}</option>
                ))}
                </select>
            </div>  
            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
        </form>
        </Dialog>
        <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2 || index==9) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingTemplateRecordAdd","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            </> 
            );
} 
