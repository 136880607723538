import React, {useContext } from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';

function Menu({ items, depth ,searchMode,displayChildren,setDisplayChildren,menuOpen,setMenuOpen}) {

  const [state,dispatch] = useContext(DatabaseContext);
  //const [displayChildren, setDisplayChildren] = useState({});
  const { user } = useContext(AuthContext);

  return (
    <ul className={` ${
      (depth===3 || depth==4 )? "bg-[#333] " : " "
      } rounded-sm pt-2 pb-2 space-y-1 text-sm`}>
      {items.map((item) => {
        if(!item.permission || user.permissions?.includes(item.permission)){
        return <React.Fragment key={item.title}>
          {item.children ?
          <li className="rounded-sm ">
            {item.children && (
              <button type="button" className={` ${
                depth===2 ? "pl-6 " : " "
                } ${
                  depth===3 ? "pl-9  " : " "
                  }
                  ${
                    depth===4 ? "pl-12 " : " "
                    } flex items-center w-full p-2 space-x-3  rounded-md group text-white hover:bg-[#555]`}
                onClick={() => {
                setDisplayChildren({
                  ...displayChildren,
                  [item.id]: !displayChildren[item.id],
                });
                if(menuOpen)setMenuOpen(false);
              }}>
              {depth===1 && (item.icon)}
              {!menuOpen && <><span 
              className="text-gray-100 flex-1 text-left whitespace-nowrap">
                {item.title}
              </span>
              {!displayChildren[item.id] ? <i className="pi pi-angle-down"></i> : <i className="pi pi-angle-up"></i>}
              </>}
          </button>
            )}
            {displayChildren[item.id] && item.children && <Menu items={item.children} depth={depth+1} searchMode={searchMode} displayChildren={displayChildren} setDisplayChildren={setDisplayChildren} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>}
          </li> 
          :

          <li 
          className={` ${
            depth===3 ? "bg-[#333] " : " "
            }
          `}>
              <button onClick={() => dispatch({type:'create_new_tab',name:item.title,page:item.page,mask: item?.mask})}
                  className={` ${
                    depth===2 ? "pl-6 " : " "
                    } ${
                      depth===3 ? "pl-9  " : " "
                      } 
                       ${
                      depth===4 ? "pl-12 " : " "
                      } flex items-center w-full p-2 space-x-3  text-white transition duration-75 rounded-lg group hover:bg-[#555]`}>{!menuOpen && item.title}</button>
          </li>
      }</React.Fragment>}
      else{
        return null;
      }
    })}
    </ul>
  );
}
export default Menu;